<template>
    <div
      :class="[
      'relative z-10 flex min-h-screen flex-col overflow-hidden overflow-x-hidden bg-white',
      { 'bg-plu-black text-white': !isRentPage } // Conditionally add classes based on the page
    ]"
    >
        <div class="grow">
            <component :is="isRentPage ? 'MenuBarRent' : 'MenuBar'" />
            <Rent v-if="isRentPage" />
            <div v-else>
                <slot></slot>
          </div>
        </div>
        <PluFooter></PluFooter>
    </div>
</template>
<script>
import { defineComponent } from 'vue'
import MenuBar from '@/Components/menu/MenuBar.vue'
import MenuBarRent from '@/Components/menu/MenuBarRent.vue'
import PluFooter from '@/Components/PluFooter.vue'
import Rent from '@/Components/rent/Rent.vue'
import { usePage } from '@inertiajs/vue3'

export default defineComponent({
    components: { PluFooter, MenuBar, MenuBarRent, Rent },
    computed: {
      isRentPage() {
        return usePage().url === '/rent'
      },
    },
})
</script>
