<template>
  <section id="rent" class="bg-white text-black">
    <!-- Render MachineInfo only when on RentInitialPage -->
    <MachineInfo v-if="isInitialPage"></MachineInfo>
    <RentTitle :stepTitle="currentStepTitle"></RentTitle>
    <inner-constraint class="h-full pt-8 pb-12 flex flex-col">
      <component
        :is="currentStep.component"
        @startProcess="startProcess"
        :processType="currentStep.selectionType"
      />
      <RentButton
        v-if="showRentButton"
        :buttonText="currentButtonText"
        @nextStep="nextStep"
      />
    </inner-constraint>
  </section>
</template>

<script setup>
import { ref, computed } from "vue";
import InnerConstraint from "@/Components/InnerConstraint.vue";
import RentTitle from "@/Components/rent/RentTitle.vue";
import RentInitialPage from "@/Components/rent/RentInitialPage.vue";
import RentDetails from "@/Components/rent/RentDetails.vue";
import RentInformation from "@/Components/rent/pick-up/RentInformation.vue";
import RentPayment from "@/Components/rent/pick-up/RentPayment.vue";
import RentComplete from "@/Components/rent/RentComplete.vue";
import RentButton from "@/Components/rent/RentButton.vue";
import MachineInfo from "@/Components/rent/MachineInfo.vue";

// Track whether the current step is RentInitialPage
const isInitialPage = ref(true);

// Initial view is the RentInitialPage component
const currentStep = ref({
  component: RentInitialPage,
  stepTitle: 'What would you like to do?',
  buttonText: null,
  selectionType: null, // Add selectionType to track if 'rent' or 'return' is selected
});

const currentStepTitle = computed(() => currentStep.value.stepTitle);
const currentButtonText = computed(() => currentStep.value.buttonText);
const showRentButton = computed(() => currentStep.value.buttonText !== null);

// Following steps
const allSteps = {
  // PICK-UP UMBRELLA
  rent: [
    {
      name: 'details',
      component: RentDetails,
      stepTitle: 'Your details',
      buttonText: 'Continue',
    },
    {
      name: 'information',
      component: RentInformation,
      stepTitle: 'How it works',
      buttonText: 'Rent your umbrella',
    },
    {
      name: 'payment',
      component: RentPayment,
      stepTitle: 'Payment',
      buttonText: 'Continue to Payment',
    },
    {
      name: 'complete',
      component: RentComplete,
      stepTitle: 'Thank you!',
      buttonText: null, // No button in the last step
    },
  ],
  // RETURN UMBRELLA
  return: [
    {
      name: 'details',
      component: RentDetails,
      stepTitle: 'Your details',
      buttonText: 'Continue',
    },
    {
      name: 'complete',
      component: RentComplete,
      stepTitle: 'Thank you for using Plu!',
      buttonText: null, // No button in the last step
    },
  ],
};

// Starts either rent or return process based on the user's selection
function startProcess(selection) {
  isInitialPage.value = false; // Turn off the initial page flag when starting a process

  currentStep.value = {
    ...allSteps[selection][0],
    selectionType: selection,
  };
}

function nextStep() {
  const selectionType = currentStep.value.selectionType;
  const currentIndex = allSteps[selectionType].findIndex(
    (step) => step.name === currentStep.value.name
  );

  if (currentIndex < allSteps[selectionType].length - 1) {
    currentStep.value = allSteps[selectionType][currentIndex + 1];
    currentStep.value.selectionType = selectionType; // Keep selection type in the next step
  }
}
</script>
